<script>
import {
    BButton,
    BCol,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormGroup,
    BFormInput,
    BRow,
    BTable
} from 'bootstrap-vue';
import api from '@/services/api';
import { copyObjectWithoutID } from '@/util/helper';
import { setLocalVar } from '@/util/localstorage-helper';

export default {
    name: 'RolesSingle',
    components: {
        BRow,
        BCol,
        BFormInput,
        BFormGroup,
        BFormCheckboxGroup,
        BFormCheckbox,
        BButton,
        BTable
    },
    data: () => ({
        role: {
            name: '',
            permission: {}
        },
        permissionKeys: ['view', 'create', 'update', 'delete', 'activate'],
        roleID: null,
        isSubmitBtnDisabled: true
    }),
    computed: {
        tableHeader() {
            return [
                this.$t('titles.title'),
                this.$t('titles.view'),
                this.$t('button.create'),
                this.$t('button.edit'),
                this.$t('button.delete'),
                this.$t('button.activate')
            ];
        },
        submitBtn() {
            return this.$route.name === 'Roles Create'
                ? this.$t('button.create')
                : this.$t('button.save');
        }
    },
    methods: {
        async getRole() {
            const { data } = await api.roles.getSingle(this.roleID);
            this.role = copyObjectWithoutID(data);
        },

        async updateRole() {
            await api.roles.put(this.roleID, this.role);
            this.$toast.success(this.$t('success.updated.title'));
            const { data } = await api.auth.authMeApi();
            setLocalVar('userData', JSON.stringify(data));
        },

        async createRole() {
            await api.roles.post(this.role);
            this.$toast.success(this.$t('success.added'));
        },

        async getPermissionsList() {
            const { data } = await api.roles.getPermissionsList();
            this.role.permission = { ...data, ...this.role.permission };
        },

        submit() {
            this.$refs.role.validate().then(async (success) => {
                if (success) {
                    try {
                        if (!this.roleID) {
                            await this.createRole();
                        } else {
                            await this.updateRole();
                        }
                        await this.$router.push({
                            name: 'Roles Index'
                        });
                        this.$router.go(0);
                    } catch (error) {
                        this.$toast.error(this.$t('error.smth'));
                    }
                }
            });
        },

        formatPermissionTitle(str) {
            return str
                .split('_')
                .map((i) => {
                    return i.charAt(0).toUpperCase() + i.slice(1);
                })
                .join(' ');
        },

        checkToExistPermissionKey(obj, key) {
            return Object.keys(obj).includes(key);
        },

        permissionChanged() {
            if (!this.isSubmitBtnDisabled) return;
            this.isSubmitBtnDisabled = false;
        }
    },
    async mounted() {
        if (this.$route.name === 'Roles Single') {
            this.roleID = this.$route.params.id;
            await this.getRole();
        }
        await this.getPermissionsList();
    }
};
</script>

<template>
    <div>
        <ValidationObserver ref="role">
            <b-row>
                <b-col cols="6">
                    <div class="d-flex">
                        <h1 class="mr-2" style="margin-top: 6px">
                            {{ $t('titles.role') }}
                        </h1>
                        <ValidationProvider
                            :name="$t('titles.title')"
                            rules="required"
                            v-slot="{ errors }"
                            style="flex-grow: 1"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="role.name"
                                    size="lg"
                                    :placeholder="$t('enter.title')"
                                    :state="errors.length > 0 ? false : null"
                                    @keypress="permissionChanged"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </div>
                </b-col>

                <b-col cols="12">
                    <div class="card">
                        <div class="informations-draggable-table">
                            <table>
                                <thead>
                                    <tr>
                                        <th v-for="(header, index) in tableHeader" :key="index">
                                            {{ header }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="(permission, permissionIndex) in role.permission"
                                        :key="permissionIndex"
                                    >
                                        <td>
                                            <strong>
                                                {{ formatPermissionTitle(permissionIndex) }}
                                            </strong>
                                        </td>
                                        <td v-for="(item, index) in permissionKeys" :key="index">
                                            <b-form-checkbox
                                                v-if="checkToExistPermissionKey(permission, item)"
                                                v-model="permission[item]"
                                                @change="permissionChanged"
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </b-col>
                <b-col cols="12" class="mb-2">
                    <b-button
                        variant="success"
                        @click="submit"
                        class="float-right"
                        :disabled="isSubmitBtnDisabled"
                    >
                        {{ submitBtn }}
                    </b-button>
                </b-col>
            </b-row>
        </ValidationObserver>
    </div>
</template>
